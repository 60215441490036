import React from "react";

function Content() {
  return (
    <div class="bg-white py-6 sm:py-8 lg:py-12">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-2">
        <div class="mb-6 flex justify-center gap-4">
          <h2 class="text-2xl  text-center font-bold text-gray-800 lg:text-5xl">
            Our Brands
          </h2>
        </div>

        <div class="py-24 grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-16 lg:grid-cols-3 xl:grid-cols-3">
          <div>
            <div class="group mb-2 block h-full lg:mb-3">
              <img
                src="/zariah (2).jpg"
                loading="lazy"
                alt=""
                class="h-full w-full object-center transition duration-200 group-hover:scale-110"
              />
            </div>
          </div>

          <div>
            <div class="group mb-2 block h-full lg:mb-3">
              <img
                src="/eleanor (2).jpg"
                loading="lazy"
                alt=""
                class="h-full w-full object-center transition duration-200 group-hover:scale-110"
              />
            </div>
          </div>

          <div>
            <div class="group mb-2 block h-full lg:mb-3">
              <img
                src="/inaya.jpg"
                loading="lazy"
                alt=""
                class="h-full w-full object-center transition duration-200 group-hover:scale-110"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
