import React from "react";
import Order from "../../components/Order/Order";

function OrderPage() {
  return (
    <div>
      <Order />
    </div>
  );
}

export default OrderPage;
