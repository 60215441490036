import React from "react";
import Fav from "../../components/Fav/Fav";

function FavPage() {
  return (
    <div>
      <Fav />
    </div>
  );
}

export default FavPage;
