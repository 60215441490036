import React from "react";
import ProductDetail from "../../components/ProductDetail/ProductDetail";

function ProductDetailPage() {
  return (
    <div>
      <ProductDetail />
    </div>
  );
}

export default ProductDetailPage;
