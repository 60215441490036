import React from "react";

function About() {
  return (
    <div>
      <div>
        <div className="py-12 bg-amber-50">
          <div className="max-w-screen-xl mx-auto ">
            <main class="">
              <div class="mb-4 md:mb-0 w-full mx-auto relative bg-pink-50 p-2">
                <div class="px-4 lg:px-4 ">
                  <h2 class="mb-1  text-3xl text-gray-800 md:mb-1 lg:text-3xl">
                    About Us :
                  </h2>
                </div>
              </div>

              <div class="flex flex-col py-10  lg:flex-row lg:space-x-12">
                <div class="px-4  lg:px-4 text-center text-lg leading-relaxed w-full lg:w-full">
                  <p class="pb-6 text-justify font-assistant ">
                    {" "}
                    From time immemorial, people are habituated to wearing
                    garments for protection, comfort and fashion. In this
                    hi-tech civilization era, people are eager to showcase their
                    fashionable modern attire as a sign of status. As a result,
                    so many manufacturers have thrived in this industry.
                    <br />
                    <br />
                    ARIZ GARMENTS is one such manufacturer of the 21st century,
                    which has made available a Pandora's Box full of exclusively
                    designed fashionable garments for all age groups. We
                    epitomize the power of quality and timeless design.
                    <br />
                    <br />
                    ARIZ GARMENTS is the El Dorado of Indian casual-wears,
                    Indian traditional-wears, Fusion-wears (combination of both
                    Indian and Western styles), western designs and fashionable
                    wears. We strongly believe our garments bring empowerment
                    and joy to the wearer.
                    <br />
                    <br />
                    ARIZ GARMENTS in order to survive and flourish in the
                    textile designer industry, has recruited highly experienced
                    technicians, skilled workers and the finest creative
                    designers from the industry. Our creativity and sense of
                    fashion is one of the best in the industry.
                    <br />
                    <br />
                    With the Certification of ISO 9001:2015 , ARIZ GARMENTS uses
                    the best quality fabrics for our garments to ensure the best
                    standard in the industry. We are presently exporting our
                    products to our neighbouring countries like Bangladesh, Sri
                    Lanka and a few countries in the Middle-East like UAE and
                    Qatar.
                    <br />
                    <br />
                    Our goal is not only to expand markets but also to satisfy
                    the interests of our loyal customers, retail clients and
                    showroom malls.
                    <br />
                    <br />
                    We at ARIZ GARMENTS make all efforts to supply any orders
                    within the stipulated time-frame. We make all endeavour to
                    take full care of our customers with honesty and sincerity.
                    We strive to satisfy the demands of modern fashion and new
                    tastes while exemplifying excellent quality and genuine
                    value.
                  </p>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="py-6"></div>
      </div>
    </div>
  );
}

export default About;
