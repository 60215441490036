import React from "react";

function AboutFeature() {
  return (
    <div class="bg-white py-6 sm:py-8 lg:py-12">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-2">
        <div class="mb-6 flex justify-center gap-4">
          <h2 class="text-2xl  text-center font-bold text-gray-800 lg:text-5xl">
            ACCREDITATION
          </h2>
        </div>
        <div class="mb-6 flex px-4 justify-start gap-4">
          <h2 class="text-2xl  text-start font-bold text-gray-800 lg:text-2xl">
            Member Of :
          </h2>
        </div>

        <div class="py-24 grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-16 lg:grid-cols-3 xl:grid-cols-4">
          <div>
            <div class="group mb-2 block h-40 lg:mb-3">
              <img
                src="/AEPC.svg"
                loading="lazy"
                alt=""
                class="h-full w-full object-center transition duration-200 group-hover:scale-110"
              />
            </div>

            <div class="flex flex-col justify-center">
              <span class="text-gray-500 text-center">
                {" "}
                Apparel Export Promotion Council (AEPC).{" "}
              </span>
            </div>
          </div>

          <div>
            <div class="group mb-2 block h-40 lg:mb-3">
              <img
                src="/FIEO.svg"
                loading="lazy"
                alt=""
                class="h-full w-full object-center transition duration-200 group-hover:scale-110"
              />
            </div>

            <div class="flex flex-col justify-center">
              <span class="text-gray-500 text-center">
                Federation of Indian Export Organisations (FIEO)
              </span>
            </div>
          </div>

          <div>
            <div class="group mb-2 block h-40 lg:mb-3">
              <img
                src="/ISO.svg"
                loading="lazy"
                alt=""
                class="h-full w-full object-center transition duration-200 group-hover:scale-110"
              />
            </div>

            <div class="flex flex-col justify-center">
              <span class="text-gray-500 text-center">
                {" "}
                An ISO 9001: 2015 Certified Company
              </span>
            </div>
          </div>

          <div>
            <div class="group mb-2 block h-40 lg:mb-3">
              <img
                src="/NABCB.svg"
                loading="lazy"
                alt=""
                class="h-full w-full object-center transition duration-200 group-hover:scale-110"
              />
            </div>

            <div class="flex flex-col justify-center">
              <span class="text-gray-500 text-center">
                {" "}
                National Accreditation Board for Certification Bodies (NABCB){" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutFeature;
